import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.footer`
height: var(--footerHeight);
display: flex;

justify-content: center;
.footer-content {
    font-size: 1.25rem;
    height: 75%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 4px;
    background: var(--footerColor);
    
}
`;

export default function Footer() {
    return (
        <FooterStyles>
            <div className='footer-content'>&copy; Traterna, LLC {new Date().getFullYear()}</div>
        </FooterStyles>
    )
}