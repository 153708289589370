import React from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo.svg';



const LogoStyles = styled.div`
    // background: url(${logo});
    background-size: 160px;
    background-repeat: no-repeat;
    padding-top: 0;
    // height: 0px;
    width: 160px;
    margin-left: 2vw;
    margin-top: 5px;
    font-size: 2.5rem;   
    transition: all 0.25s ease-in-out;
    color: black;
    
    
`;

export default function Logo() {
    return (
        <LogoStyles>
            <div>TRATERNA</div>
        </LogoStyles>
    )
}